import React, { useEffect, useState } from "react";
import useRequest from "../../hooks/useRequest";
import { Avatar, Button, List, Skeleton } from "antd";
import moment from "moment";
import LogoMImg from "../../assets/images/logo-black-main.png"
import { Severty, ShowToast } from "../../helper/toast";

export const NotificationList = () => {
  const [loading, setLoading] = useState();
  const [list, setList] = useState([]);
  const { request } = useRequest()
  const [refresh,setRefresh] = useState(false)

  const dates = {
    today: moment(new Date()).format("YYYY-MM-DD"),
    yesterday: moment(new Date().getTime() - 24 * 60 * 60 * 1000).format(
      "YYYY-MM-DD",
    ),
  };

  console.log(dates, "datedmjgfjhg");

  const fetchData = () => {
    request({
      url:'/app/notification/list',
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data?.data ?? [])
        // setPagination(prev => ({ current: pagination?.current, total: data?.data?.length }))
      },
      onError: (error) => {
        setLoading(false)
        // ShowToast(error, Severty.ERROR)
      }
    })
    // setList(dummyNotifications);
    setLoading(false);
  };


  const readNotification = (Id) => {
    request({
      url:`/app/notification/read?id=${Id}`,
      method: 'post',
      onSuccess: (data) => {
        setLoading(false)
        setRefresh(prev=> !prev)
        ShowToast(data?.message, Severty.SUCCESS)  
      
      },
      onError: (error) => {
        setLoading(false)
      }
    })
    setLoading(false);
  };

  const deleteNotification = (Id) => {
    request({
      url:`/app/notification/delete?id=${Id}`,
      method: 'post',
      onSuccess: (data) => {
        setLoading(false)
        setRefresh(prev=> !prev)
        ShowToast(data?.message, Severty.SUCCESS)  
      },
      onError: (error) => {
        setLoading(false)
      }
    })
    setLoading(false);
  };




  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [refresh]);



  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <>
      
         
              <div className="notification-card" key={"groupItem"}>
                
                <List
                  itemLayout="horizontal"
                  dataSource={list}
                  renderItem={(item) => (
                    <List.Item
                 
                    >
                      <List.Item.Meta
                  className={`remamber-main ${item?.is_read ? "Notification-read" : "Notification-unread"}`}

                        avatar={<Avatar className="notifiaction-logo-img-main" src={LogoMImg} />}
                        title={
                          <div>
                         
                            <div>{item?.description}</div>
                          </div>
                        }
                        description={
                          <div>
                            <div>
                          {moment(item?.created_at).format("YYYY-MM-DD HH:mm:ss")}

                            </div>
                            <div>
                            {!item.is_read ? <Button  className="btnStyle btn_primary" onClick={() => readNotification(item._id)}>
                              <i className="fa fa-light fa-eye-slash"></i>  </Button> : ""}
                          <Button className="btnStyle btn_primary" onClick={() => deleteNotification(item._id)}>  <i className="fa fa-light fa-trash"></i> </Button>
                            </div>
                          </div>
                        }
                        
                      />
                    </List.Item>
                  )}
                />
              </div>
            
           
        </>
      )}
    </>
  );
  
  
};

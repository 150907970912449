import {
  Button,
  DatePicker,
  Input,
  Table,
  Tag,
  Row,
  Col,
  Select,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import useApi from "../../../hooks/useApi";
import { calculateAge } from "../../../helper/functions";

import Plus from "../../../assets/images/plus.svg";
import ConfirmationBox from "../../../components/ConfirmationBox";
import SectionWrapper from "../../../components/SectionWrapper";
import apiPath from "../../../constants/apiPath";
import { AppStateContext, useAppContext } from "../../../context/AppContext";
import lang from "../../../helper/langHelper";
import { Severty, ShowToast } from "../../../helper/toast";
import useDebounce from "../../../hooks/useDebounce";
import useRequest from "../../../hooks/useRequest";
import { useNavigate } from "react-router";

const { RangePicker } = DatePicker;
const { Option } = Select;

function Index() {
  const heading = lang("Patient management");
  const { setPageHeading } = useContext(AppStateContext);
  const { country } = useAppContext();
  const { getState, getCity, getCountry } = useApi();

  const sectionName = "Patient";
  const routeName = "patient";
  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("status");

  const api = {
    patient: apiPath.listPatient,
    addEdit: apiPath.listPatient,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const navigate = useNavigate();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [countries, setCountries] = useState();
  const [states, setStates] = useState();

  const [cities, setCities] = useState();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [selectedOptionsCountries, setSelectedOptionsCountries] = useState();
  const [selectedState, setselectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();
  //For Filters
  const [filter, setFilter] = useState();

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);

  const onDelete = (id) => {
    request({
      url: api.patient + "/" + id,
      method: "DELETE",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

 


  const columns = [
    {
      title: "UHID",
      dataIndex: "uhid",
      key: "uhid",
      filters: [
        {
          text: "A-Z",
          value: 1,
        },
        {
          text: "Z-A",
          value: -1,
        },
      ],
      filterMultiple: false,
      width: 200,
      render: (_, { uhid, _id }) => {
        return uhid ? (
          <Link to={`/${routeName}/history/${_id}`}> {uhid} </Link>
        ) : (
          _id
        );
      },
    },
  
    {
      title: "Salutation",
      dataIndex: "name",
      key: "salutation",
      width: 200,
      render: (_, { salutation }) => {
        return salutation ? salutation.trim() : "-"; // If salutation exists, return it, otherwise return a placeholder
      },
    },

    {
      title: "First Name",
      dataIndex: "name",
      key: "firstName",
      filterMultiple: false,
      width: 150,
      onFilter: (value, record) => {
        const firstName = record?.name?.split(" ")[0] || "";
        return firstName.toLowerCase().startsWith(value.toLowerCase());
      },
      sorter: (a, b) => {
        const firstNameA = (a?.name?.split(" ")[0] || "").toLowerCase();
        const firstNameB = (b?.name?.split(" ")[0] || "").toLowerCase();
        return firstNameA.localeCompare(firstNameB);
      },
      render: (text, record) => {
        const firstName = record?.name?.split(" ")[0] || "-";
        return firstName;
      },
    },
    {
      title: "Last Name",
      dataIndex: "name",
      key: "lastName",
      filterMultiple: false,
      width: 150,
      onFilter: (value, record) => {
        const [, ...lastNameParts] = (record?.name || "").split(" ");
        const lastName = lastNameParts.join(" ");
        return lastName.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => {
        const [, ...lastNamePartsA] = (a?.name || "").split(" ");
        const lastNameA = lastNamePartsA.join(" ");
        const [, ...lastNamePartsB] = (b?.name || "").split(" ");
        const lastNameB = lastNamePartsB.join(" ");
        return lastNameA.localeCompare(lastNameB);
      },
      render: (text, record) => {
        const [, ...lastNameParts] = (record?.name || "").split(" ");
        return lastNameParts.join(" ") || "-";
      },
    },

    {
      title: "Phone Number",
      render: (_, { mobile_number, country_code }) => {
        return (
          (country_code ? `+${country_code}` : "-") +
          (mobile_number ? mobile_number : "")
        );
      },
    },
    {
      title: "Email ID",
      dataIndex: "email",
      key: "email",
      // filters: [
      //   {
      //     text: "A-Z",
      //     value: 1,
      //   },
      //   {
      //     text: "Z-A",
      //     value: -1,
      //   },
      // ],
      filterMultiple: false,
      render: (_, { email }) => {
        return email ? (
          <span style={{ textTransform: "lowercase" }}>{email}</span>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Gender",
      key: "gender",
      dataIndex: "gender",
    },
    {
      title: "Age",
      key: "age",
      dataIndex: "age",
      render: (_, { dob }) => {
        const age = calculateAge(dob ? dob : 0);
        console.log(age, "age>>>>>>>>>>>");
        return (
          <span>
            {" "}
            {age
              ? age.years && age.years !== 0
                ? `${age.years} years`
                : age.months && age.months !== 0
                ? `${age.months} months`
                : age.days && age.days !== 0
                ? `${age.days} days`
                : "-"
              : "-"}
          </span>
        );
      },
    },
    {
      title: "DOB",
      key: "dob",
      dataIndex: "dob",
      render: (dob) => <span>{dob}</span>,
    },
    {
      title: "Postal Code ",
      key: "postal_code",
      dataIndex: "postal_code",
    },
    {
      title: "City",
      key: "city",
      dataIndex: "city",
      render: (_, { city }) => {
        return <spna>{city?.name}</spna>;
      },
    },
    {
      title: "State",
      key: "state",
      dataIndex: "state",
      render: (_, { state }) => {
        return <spna>{state?.name}</spna>;
      },
    },
    {
      title: "Country",
      key: "country",
      dataIndex: "country",
      render: (_, { country }) => {
        return <spna>{country?.name}</spna>;
      },
    },
    {
      title: "Address",
      key: "address",
      dataIndex: "address",
      render: (_, { addressDet }) => {
        return <spna>{addressDet?.address}</spna>;
      },
    },
    {
      title: "Status",
      key: "is_active",
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? "green" : "red";
        return (
          <a>
            <Tag
              // onClick={(e) =>
              //   showConfirm({
              //     record: _id,
              //     path: api.patient + "/status",
              //     onLoading: () => setLoading(true),
              //     onSuccess: () => setRefresh((prev) => !prev),
              //   })
              // }
              color={color}
              key={is_active}
            >
              {is_active ? "Active" : "Inactive"}
            </Tag>
          </a>
        );
      },
    },
    {
      title: "Register Date",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("lll");
      },
    },
    {
      title: "Action",
      fixed: "right",
      key: "action",
      render: (_, record) => {
        return (
          <div div className="d-flex justify-contenbt-start">
            <>
              <Tooltip
                title={"View History"}
                color={"purple"}
                key={"activity user"}
              >
                <Button
                  className="btnStyle primary_btn"
                  onClick={(e) =>  navigate(`/${routeName}/history/${record._id}`)}
                >
                 <i className="fa fa-light fa-eye"></i>

                </Button>
              </Tooltip>
              <Tooltip
                title={"View History"}
                color={"purple"}
                key={"activity user"}
              >
                <Button
                  className="btnStyle primary_btn"
                  onClick={(e) =>  navigate(`/${routeName}/history/${record._id}`)}
                >
                 <i className="fa fa-light fa-list"></i>

                </Button>
              </Tooltip>
            
            </>
          </div>
        );
      },
    },
  ];
  const handleChangeCountries = (selectedValues) => {
    setSelectedOptionsCountries(selectedValues);
    getState({
      countryId: selectedValues,
      stateData: (data) => {
        setStates(data);
      },
    });
  };

  const handleChangeState = (selectedValues) => {
    setselectedState(selectedValues);
    getCity({
      stateId: selectedValues,
      cityData: (data) => {
        setCities(data);
      },
    });
  };

  const handleChangeCity = (selectedValues) => {
    setSelectedCity(selectedValues);
  };
  useEffect(() => {
    getCountry({
      countryData: (data) => setCountries(data),
    });
  }, []);
  useEffect(() => {
    setLoading(true);
    fetchData({ ...pagination, current: 1 }, filter);
  }, [
    refresh,
    debouncedSearchText,
    startDate,
    endDate,
    selectedCity,
    selectedState,
    selectedOptionsCountries,
  ]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;
    const filterUhid = filters ? filters.uhid : null;
    const filterName = filters ? filters.name : null;
    const filterEmail = filters ? filters.email : null;
    const filterUserId = filters ? filters.userId : null;

    console.log(filterActive, "filterActive");
    request({
      url:
        api.patient +
        `?status=${filterActive ? filterActive.join(",") : ""}&uhid=${
          filterUhid ? filterUhid.join(",") : ""
        }&userId=${filterUserId ? filterUserId.join(",") : ""}&name=${
          filterName ? filterName.join(",") : ""
        }&email=${filterEmail ? filterEmail.join(",") : ""}&page=${
          pagination ? pagination.current : 1
        }&pageSize=${
          pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}${path ? `&status=1` : ""}&start_date=${
          startDate ? startDate : ""
        }&end_date=${endDate ? endDate : ""}&country=${
          selectedOptionsCountries ? selectedOptionsCountries : ""
        }&state=${selectedState ? selectedState : ""}&city=${
          selectedCity ? selectedCity : ""
        }`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          setList(data.data);
          setTotalRecords(data?.data?.length);
          setPagination((prev) => ({
            current: pagination?.current,
            total: data?.data?.length,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    setFilter(filters);
    fetchData(pagination, filters);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"));
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"));
    } else {
      setStartDate();
      setEndDate();
    }
  };

  const handleExport = () => {
    const data =
      list &&
      list.length > 0 &&
      list.map((row, index) => ({
        "S.No.": index,
        "Patient Id": row.uhid,
        "User Id": row.userDet.uhid,
        Salutation: row.salutation,
        Name: row.name,
        "Mobile No.": `${row.country_code}${row.mobile_number}`,
        Email: row.email,
        Gender: row.gender,
        Age: (() => {
          const age = calculateAge(row.dob);
          return age
            ? `${age.years} years ${age.months} months ${age.days} days`
            : "";
        })(),
        "Postal Code": row.postal_code,
        Location: row.location,
        City: row.citiesDet?.name,
        State: row.stateDet?.name,
        Country: row.countryDet?.name,
        Address: row.addressDet?.address,
        Status: row.is_active,
        "Registered On": moment(row.created_at).format("DD_MM_YYYY"),
      }));
    // alert(row.languageId.name)

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Patient Data");
    XLSX.writeFile(
      workbook,
      `${
        moment().milliseconds() +
        1000 * (moment().seconds() + 60 * 60) +
        "-access"
      }.xlsx`,
    );
  };
  const handleReset = () => {
    setSelectedCity(null);
    setselectedState(null);
    setSelectedOptionsCountries(null);
    setRefresh((prev) => !prev);
    setPagination({ current: 1, pageSize: 10 });
    setStartDate();
    setEndDate();
    setSearchText("");
    setStates([])
    setCities([])
  };

  return (
    <>
      <SectionWrapper
        cardHeading={lang("Patients") + " " + lang("list")}
        extra={
          <>
            <div className="w-100 d-grid align-items-baseline text-head_right_cont">
              <div className="pageHeadingSearch pageHeadingbig d-flex gap-2">
                <RangePicker
                  style={{ height: 44 }}
                  disabledDate={(current) => current.isAfter(Date.now())}
                  value={[
                    startDate ? moment(startDate) : null,
                    endDate ? moment(endDate) : null,
                  ]}
                  onChange={handleChangeDate}
                />
                <Input.Search
                  className="searchInput"
                  placeholder="Name, Number, Email, UHID, UserId"
                  onChange={onSearch}
                  value={searchText}
                  allowClear
                />
              
                {/* <Button
                  className="btnStyle  primary_btn"
                  onClick={() => handleExport()}
                >
                  Export
                </Button> */}
              </div>
              <Row className="justify-content-end gap-2">
                <Select
                  showSearch
                  className="multiselect"
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  placeholder="Select Country"
                  // mode="multiple"
                  value={selectedOptionsCountries}
                  onChange={handleChangeCountries}
                >
                  {countries && countries.length > 0
                    ? countries.map((item, index) => (
                        <Option value={item._id}>{item.name}</Option>
                      ))
                    : null}
                </Select>

                <Select
                  showSearch
                  className="multiselect"
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  placeholder="Select State"
                  // mode="multiple"
                  value={selectedState}
                  onChange={handleChangeState}
                >
                  {states && states.length > 0
                    ? states.map((item, index) => (
                        <Option value={item._id}>{item.name}</Option>
                      ))
                    : null}
                </Select>

                <Select
                  showSearch
                  className="multiselect"
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  placeholder="Select City"
                  // mode="multiple"
                  value={selectedCity}
                  onChange={handleChangeCity}
                >
                  {cities && cities.length > 0
                    ? cities.map((item, index) => (
                        <Option value={item._id}>{item.name}</Option>
                      ))
                    : null}
                </Select>
                <Button
                  className="btnStyle  primary_btn"
                  onClick={() => handleReset()}
                >
                  Reset
                </Button>
              </Row>
            </div>
          </>
        }
      >
        <h4 className="text-right">TotalRecords: {totalRecords}</h4>
        <div className="table-responsive customPagination">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={pagination}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>

     

     
    </>
  );
}

export default Index;

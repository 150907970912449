import { Col, Form,     TimePicker  , Modal, Row, Select, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";

import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
const format = "h:mm a";
const {Option} = Select;

const AddForm = ({ section, api, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [file, setFile] = useState([]);
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [slotsList, setSlotsList] = useState([]);
  const [slotTimings,setSlotTimings] = useState([])
  const [doctorList, setDoctorList] = useState([]);
  const [selectedDate, setSelectedDate] = useState()

  const onCreate = (values) => {
    
    request({
      url: apiPath.appointment + "/" + data._id ,
      method:  "PUT"   ,
      data: values,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const getDoctorList = () => {
    request({
      url: apiPath.doctors,
      method: "GET",
      onSuccess: (data) => {
        setDoctorList(data.data);
      },
      onError: (err) => {},
    });
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        appointment_date: moment(data.appointment_date), // Assuming data.appointment_date is in a suitable format
        appointment_time: data.appointment_time, // Assuming data.appointment_time is already formatted
        doctor_id: data.doctor_id,
        // Set other form fields here based on the data object
      });
    }
    getDoctorList(); // Move the getDoctorList call outside of the if block
  }, [data, form]); // Add form dependency to useEffect dependency array

  // useEffect(() => {
  //   handleChange()
  // })


  const handleChange = (date) => {
    request({
      url: apiPath.slots+ `/${date}`,
      method: "GET",
      onSuccess: (data) => {
        setSlotsList(data.data);
      },
      onError: (err) => {},
    });
  }

  const handleDay = (day) => {
    const slotting = slotsList.filter((slots) => slots.slot_day == day)
    console.log(slotting, 88)
    setSlotTimings(slotting)

  }
  const handleDateChange = (date, dateString) => {
    setSelectedDate(dateString); 
    console.log('Selected Date:', new Date(dateString));

    handleChange(dateString)
    // Update the state with the selected date string
  };

  // const disabledDate = (current) => {
  //   // Example: Disable dates after today
  //   const isBeforeToday = current && current < moment().startOf('day');
  //   // Disable dates after 15 days
  //   const isAfterFifteenDays = current && current > moment().add(15, 'days').endOf('day');
  //   return isBeforeToday || isAfterFifteenDays;  
  // };
  const disabledDate = (current) => {
    // Can not select days before 48 hours from now and after 7 days from now
    return current && (current < moment().add(48, 'hours').startOf('day') || current > moment().add(7, 'days').endOf('day'));
  };

  const handleTime = (slot) => {

    const selectedSlot = slotsList.filter((slotss) => slotss.slot_time_from == slot);
        console.log("Selected slot:", doctorList);
        var additional = []
      let updatedDoctorList = [...doctorList];

      selectedSlot.forEach(slot => {
          const docSlots = doctorList.filter(doc => doc._id === slot.doctor_id);
          updatedDoctorList = [...docSlots];
      });

      setDoctorList(updatedDoctorList);

  }

  function convertToIST(time) {
    // Parse the original time
    const [hours, minutes] = time.split(":").map(Number);
  
    // Indian Standard Time (IST) offset
    const IST_offset_hours = 5;
    const IST_offset_minutes = 30;
  
    // Add IST offset
    let IST_hours = hours + IST_offset_hours;
    let IST_minutes = minutes + IST_offset_minutes;
  
    // Adjust if minutes exceed 60
    if (IST_minutes >= 60) {
      IST_hours += 1;
      IST_minutes -= 60;
    }
  
    // Adjust if hours exceed 24
    IST_hours %= 24;
  
    // Format IST time
    return `${String(IST_hours).padStart(2, '0')}:${String(IST_minutes).padStart(2, '0')}`;
  }
  
  const uniqueSlots = new Set();

  // Populate the Set with unique time slots
  slotsList.forEach((slot) => {
    // Convert slot times to IST
    const IST_slot_time_from = convertToIST(slot.slot_time_from);
    const IST_slot_time_to = convertToIST(slot.slot_time_to);
  
    // Construct slot string in IST format
    const slotString = `${IST_slot_time_from}-${IST_slot_time_to}`;
  
    // Add the slot string to the Set
    uniqueSlots.add(slotString);
  });

  return (
    <Modal
      open={show}
      width={750}
      okText={data ? `Update` : `Add`}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
    >
      <Form
        id="create"
        form={form}
        onFinish={onCreate}
        layout="vertical"
        initialValues={{
          is_active: true,
        }}
      >
        <h4 className="modal_title_cls">
          {`Edit Appointment` }
        </h4>
        <Row gutter={[16, 0]}>
         
          <Col>
            <Form.Item
              className="qty-cls "
              style={{ minWidth: "180px" }}
              name="appointment_date"
              label="Select Date"
            >
              <DatePicker onChange={handleDateChange} disabledDate={disabledDate}/>
            </Form.Item>

          </Col>

          <Col>
            <Form.Item
              className="qty-cls "
              label="Enter Time"
              style={{ minWidth: "180px" }}
              name="appointment_time"

            >

              <Select onChange={handleTime} placeholder="Select Time">
                {/* Iterate over unique time slots in the Set */}
                {[...uniqueSlots].map((slotString) => {
                  // Split the slotString to get start and end times
                  const [startTime, endTime] = slotString.split('-');
                  return (
                    <Option key={slotString} value={startTime}>
                      {startTime} - {endTime}
                    </Option>
                  );
                })}
              </Select>

            </Form.Item>

          </Col>
          <Col span={24} sm={24}>
            <Form.Item
              label="Select Doctor"
              name="doctor_id"
              rules={[{ required: true, message: "Please select a doctor!" }]}
            >
              <Select
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Select Doctors"
                showSearch
                onChange={handleChange}

              >
                {console.log(doctorList, 'doc>>>>>.')}
                {doctorList?.map((item) => (

                  <Option
                    key={item?._id}
                    label={item?.name}
                    value={item?._id}
                  >
                    {console.log(item.name, "item")}
                    <span>{item?.name}</span>
                    <span>{item?.is_head_doctor ? " (Head Doctor)" : ""}</span>
                    <span>
                      {item?.specialty && " ( " + item?.specialty + " )"}
                    </span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col> 


          {/*  show the availability of doctor */}
          {/* reschedule appointment as per availability (date + time) */}
        </Row>
      </Form>
    </Modal>
  );
};

export default AddForm;

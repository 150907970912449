import { Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import useApi from "../../hooks/useApi";
import apiPath from "../../constants/apiPath";
const { Option } = Select;
const AddForm = ({ section, api, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const {  getState, getCity, getCountry } = useApi();
  
  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];
  const [image, setImage] = useState();
  const [location, setLocation] = useState();
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState()
  const [states, setStates] = useState()


  const [cities, setCities] = useState()
  const [mobileNumber, setMobileNumber] = useState({
    mobile_number: "",
    country_code: "",
  });

  const handleChange = (value, data) => {
    var country_code = data.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data.dialCode.length),
    });
  };




  useEffect(() => {
    
    if (!data) return;

    setLocation({
      location: data?.location,
      latitude: data?.latitude,
      longitude: data?.longitude,
      city: data?.city,
      country: data?.country,
      postal_code: data?.postal_code,
    });

    form.setFieldsValue({
      ...data,
      dob: moment(data.dob),
    });
    getCity({
      stateId: data.state,
      cityData: (data) => setCities(data)
    })
    getState({
      countryId: data.country,
      stateData: (data) => {setStates(data)
      }
    })
    setImage(data.image);
    setMobileNumber({
      mobile_number: data.mobile_number,
      country_code: data.country_code,
    });
  }, [data]);

  const handleChangeLocation = (val) => {
    setLocation(val);
  };
  useEffect(() => {
    getCountry({
      countryData: (data) => setCountries(data)
    });
   
  }, [])
  const handleSelectChange = (value) => {

    getState({
         countryId: value,
         stateData: (data) => {setStates(data)
         }
       })
     };
     const handleStateChange = (value) => {
   
       getCity({
         stateId: value,
         cityData: (data) => setCities(data)
       })
        };
   
  const onCreate = (values) => {
    const { doctorId, startDate, endDate, reason, type, otherTypeDescription } = values;
    const payload = {};
    setLoading(true);
    payload.doctorId = doctorId;
    payload.startDate = startDate;
    payload.endDate = endDate;
    payload.reason = reason;
    payload.type = type;
  
    if (type === 'Other') {
      payload.otherTypeDescription = otherTypeDescription;
    }

    request({
      url: apiPath.addLeave,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
    open={show}
    width={750}
    okText={data ? "Update" : "Add"}
    onCancel={hide}
    okButtonProps={{
      form: "create",
      htmlType: "submit",
      loading: loading,
    }}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className="tab_modal"
  >
    <Form
      id="create"
      form={form}
      onFinish={onCreate}
      layout="vertical"
      initialValues={{
        startDate: moment(new Date(), "YYYY-MM-DD"), // Example initial value
        endDate: moment(new Date(), "YYYY-MM-DD"), // Example initial value
      }}
    >
      <div className="add_user_title">
        <h4 className="modal_title_cls">{`${data ? "Edit Leave" : "Add Leave"}`}</h4>
      </div>
      <Row gutter={[24, 0]}>
        <Col span={24} lg={12} sm={12}>
          <Form.Item
            label="Start Date"
            name="startDate"
            rules={[
              { required: true, message: "Please select the start date" },
              {
                validator: (_, value) => {
                  if (value && value.isBefore(moment(), 'day')) {
                    return Promise.reject(new Error("Please select a date after today"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
  
        <Col span={24} lg={12} sm={12}>
          <Form.Item
             label="End Date"
             name="endDate"
             dependencies={['startDate']} // This ensures the end date depends on the start date
             rules={[
               { required: true, message: "Please select the end date" },
               ({ getFieldValue }) => ({
                 validator(_, value) {
                   const startDate = getFieldValue("startDate");
                   if (value && startDate && value.isBefore(startDate, 'day')) {
                     return Promise.reject(new Error("End date must be after or the same as start date"));
                   }
                   return Promise.resolve();
                 },
               }),
             ]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
  
        <Col span={24} lg={24} sm={24}>
          <Form.Item
            label="Reason"
            name="reason"
            rules={[
              {
                required: true,
                message: "Please enter the reason",
              },
            ]}
          >
            <Input.TextArea rows={4} placeholder="Enter reason for leave" />
          </Form.Item>
        </Col>
  
        <Col span={24} lg={24} sm={24}>
          <Form.Item
            label="Type"
            name="type"
            rules={[{ required: true, message: "Please select the leave type" }]}
          >
            <Select placeholder="Select Type">
              <Option value="Casual">Casual</Option>
              <Option value="Medical">Medical</Option>
              <Option value="Others">Other</Option>
            </Select>
          </Form.Item>
        </Col>

        {form.getFieldValue('type') === 'Other' && (
          <Col span={24} lg={24} sm={24}>
            <Form.Item
              label="Other Type Description"
              name="otherTypeDescription"
              rules={[{ required: true, message: "Please provide a description for 'Other' type" }]}
            >
              <Input.TextArea rows={2} placeholder="Enter description" />
            </Form.Item>
          </Col>
        )}

      </Row>
    </Form>
  </Modal>
  
  );
};

export default AddForm;

import {
  Button,
  Card,
  Col,
  Form,
  Row,
  TimePicker,
  Typography,
} from "antd";
import {
  PlusOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { AppStateContext } from "../../context/AppContext";
import apiPath from "../../constants/apiPath";

const { Title } = Typography;

export const QuoteStatus = {
  REQUEST: "request",
  RECEIVED: "received",
  COMPLETE: "complete",
  FULLFILL: "fulfill",
  ADDONS: "addons",
  ITEMDEALS: "itemdeals",
};

function EditAvailability() {
  const [loading, setLoading] = useState(false);
  const { setPageHeading } = useContext(AppStateContext);

  const api = {
    status: apiPath.statusQuote,
    list: apiPath.listQuote,
  };
  const navigate = useNavigate();
  const { request } = useRequest();

  const getBrandList = () => {
    request({
      url: apiPath.brandList,
      method: "GET",
      onSuccess: (data) => { },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    setPageHeading("Availability");
  }, []);

  const [selectedDay, setSelectedDay] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const getCurrentWeekDates = () => {
    const startOfWeek = moment().startOf('week');
    const endOfWeek = moment().endOf('week');
    const days = [];

    for (let day = startOfWeek; day <= endOfWeek; day = day.clone().add(1, 'd')) {
      days.push(day);
    }

    return days;
  };

  const handleDaySelect = (day) => {
    setSelectedDay(day);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };
  const weekDays = {
    "Sunday": 0,
    "Monday": 1,
    "Tuesday": 2,
    "Wednesday": 3,
    "Thursday": 4,
    "Friday": 5,
    "Saturday": 6,
  }
  const handleEndTimeChange = (time) => {
    setEndTime(time);
  };

  const handleSubmit = () => {
    setLoading(true)
    let data;
    if (selectedDay && startTime && endTime) {
      const dayName = selectedDay.format("dddd"); // Get the full day name with first letter capitalized
      if (weekDays[dayName] !== undefined) { // Check if the day name is valid
        data = {
          availability: [
            {
              time_slots: [
                [
                  selectedDay.clone().set({
                    hour: startTime.hour(),
                    minute: startTime.minute(),
                    second: 0,
                  }).toISOString(),
                  selectedDay.clone().set({
                    hour: endTime.hour(),
                    minute: endTime.minute(),
                    second: 0,
                  }).toISOString(),
                ],
              ],
              day: dayName, // Use the day name with the first letter capitalized
            },
          ],
        };

        request({
          url: apiPath.addAvailability, // Adjust this to your actual API path
          method: "POST",
          data,
          onSuccess: (response) => {
            ShowToast("Availability added successfully!", Severty.SUCCESS);
            navigate("/availability")
            setLoading(false)
          },
          onError: (error) => {
            setLoading(false)
            ShowToast("Failed to add availability", Severty.ERROR);
          },
        });
      } else {
        setLoading(false)
        ShowToast("Invalid day selected", Severty.WARNING);
      }
    } else {
      setLoading(false)
      ShowToast("Please select all fields", Severty.WARNING);
    }
  };


  return (
    <>
      <div className="home-card tabled quoteManagement card_body_space">
        <Row gutter={[24, 0]}>
          <Col span={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div className="graph-title">
                <Title level={5}>Availability</Title>
              </div>

              <div className="graph_inner_title date-g">
                <h3>Select date</h3>
              </div>

              <div className="date-list">
                <ul>
                  {getCurrentWeekDates().map((date, index) => (
                    <li key={index} className={selectedDay && selectedDay.isSame(date, 'day') ? "active" : ""} onClick={() => handleDaySelect(date)}>
                      <div className="date-circsl">
                        {/* {date.date()} */}
                        <span>{date.format("ddd")}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              {/* <div className="select-all-dates">
                <Button className="ant-btn ant-btn-default btnStyle  primary_btn"><PlusOutlined />Add more</Button>
              </div> */}

              <div className="date-end-list">
                <Row gutter={[64, 0]}>
                  <Col span={24} lg={12} sm={12}>
                    <Form.Item
                      label="Start Time"
                      name="startDate"
                      rules={[
                        { required: true, message: "Please select the Start Time" },
                      ]}
                    >
                      <TimePicker
      style={{ width: "100%" }}
      onChange={handleStartTimeChange}
      value={startTime}
      minuteStep={30}      // Set minute step to 30 for 30-minute intervals
      use12Hours           // Enable 12-hour time format (AM/PM)
      format="h:mm A"      // Display time in 12-hour format with AM/PM
    />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={12} sm={12}>
                    <Form.Item
                      label="End Time"
                      name="endDate"
                      dependencies={['startDate']} // Dependency on Start Time
    rules={[
      { required: true, message: "Please select the End Time" },
      ({ getFieldValue }) => ({
        validator(_, value) {
          const startTime = getFieldValue('startDate');

          if (value && startTime) {
            const startTimeMoment = moment(startTime, 'h:mm A');
            const endTimeMoment = moment(value, 'h:mm A');

            if (endTimeMoment.isBefore(startTimeMoment)) {
              return Promise.reject(new Error("End Time must be after Start Time"));
            }
          }

          return Promise.resolve();
        },
      }),
    ]}
                    >
                         <TimePicker
      style={{ width: "100%" }}
      onChange={handleEndTimeChange}
      value={endTime}
      minuteStep={30}      // Set minute step to 30 for 30-minute intervals
      use12Hours           // Enable 12-hour time format (AM/PM)
      format="h:mm A"      // Display time in 12-hour format with AM/PM
    />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div className="add-slots-button">
                <Button className="ant-btn ant-btn-default btnStyle  primary_btn" onClick={handleSubmit} loading={loading} disabled={loading}>
                  Add Slots
                </Button>
              </div>

              <div className="back-button-main">
                <Button className="ant-btn ant-btn-default btnStyle primary_btn" onClick={() => navigate('/availability')}>
                  <ArrowLeftOutlined /> Back
                </Button>
              </div>

            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default EditAvailability;

import { Row, Col, Card, Button, Skeleton, Image, Divider } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { Badge } from 'antd';
import { shortLang, longLang } from "../../config/language";
import moment from "moment";
import notfound from "../../assets/images/not_found.png";
import pdffile from "../../assets/images/pdf-file.png";

function View() {
  const sectionName = "Appointment";
  const routeName = "appointments";

  const params = useParams();
  const { request } = useRequest();
  const [list, setList] = useState({});
  const [cart, setCart] = useState({})
  const [loading, setLoading] = useState(false);

  const fetchData = (id) => {
    request({
      url: apiPath.viewAppointment + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data[0]);
        setCart(data.getCart)
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchData(params.id);
  }, []);

  return (
    <>
      <Card className="appointment-h" title={sectionName + " Details"}>
        <Row gutter={16}>
          <Col span={12} xs={24} md={24}>
            {loading ? (
              <Skeleton active />
            ) : (
              <div className="view-main-list">
                <div className="view-inner-cls">
                  <h5>Appointment Id:</h5>
                  <h6 className="cap">{list?.appointment_id || '-'}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Doctor:</h5>
                  <h6 className="cap">
                    <Image className="imagefix" src={list?.doctor?.image || notfound} />
                    <Link to={`/doctor/view/${list?.doctor?._id}`}>{list?.doctor?.name || '-'}</Link>
                  </h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Patient:</h5>
                  <h6 className="cap">
                    <Image
                      className="imagefix"
                      src={list?.patient_details?.image || notfound}
                    />
                    <Link to={`/patient/view/${list?.patient_details?._id}`}>{list?.patient_details?.name || '-'}</Link>
                  </h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Charges:</h5>
                  <h6 className="cap">${list?.price || '-'}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Appointment Date:</h5>
                  <h6 className="cap">{list?.appointment_date ? moment(list?.appointment_date).format('DD-MMM-YYYY') : '-'}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Appointment Time:</h5>
                  <h6 className="cap">{list?.appointment_time || '-'}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Appointment Type:</h5>
                  <h6 className="cap">{list?.appointment_type || '-'}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Appointment Status:</h5>
                  <h6 className="cap">{list?.appointment_status || '-'}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Appointment Category:</h5>
                  <h6 className="cap">{list?.appointment_category || '-'}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Current Status:</h5>
                  <h6 className="cap">{list?.status || '-'}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Created On:</h5>
                  <h6>{list?.created_at ? moment(list?.created_at).format('DD-MMM-YYYY') : '-'}</h6>
                </div>

                <div className="pdf-view">
                  {list?.patient_details?.prescription && (
                    <div className="view-inner-view">
                      <h5>Prescription PDF:</h5>
                      <div className="pdf-icons"><i className="fas fa-file-pdf"></i></div>
                      <h6>
                        <a href={list.patient_details.prescription} target="_blank" rel="noopener noreferrer">
                          View Prescription PDF
                        </a>
                      </h6>
                    </div>
                  )}
                  {list?.patient_details?.advisory && (
                    <div className="view-inner-view">
                      <h5>Advisory Notes:</h5>
                      <div className="pdf-icons"><i className="far fa-file"></i></div>
                      <h6>
                        <a href={list.patient_details.advisory} target="_blank" rel="noopener noreferrer">
                          View Notes PDF
                        </a>
                      </h6>
                    </div>
                  )}
                  {list?.labReports && (
                    <div className="view-inner-view">
                      <h5>Lab Reports:</h5>
                      <div className="pdf-icons"><i className="far fa-file-alt"></i></div>
                      <h6>
                        <a href={list.labReports} target="_blank" rel="noopener noreferrer">
                          View Lab Reports
                        </a>
                      </h6>
                    </div>
                  )}
                </div>

                <Divider />
                <h5>Cart Details:</h5>

                <>
                  <div className="view-inner-cls">
                    <h5>Cart Status:</h5>
                    <h6 className="cap">{cart?.cartStatus || '-'}</h6>
                  </div>
                  {cart?.products && cart?.products.length > 0 ? (
                    cart?.products?.map((product, index) => (
                      <div key={index} className="view-inner-cls">
                        <h5>{product?.name}:</h5>
                        <h6 className="cap">
                          <Image className="imagefix" src={product?.image || notfound} />
                          {product?.generic_name || '-'}
                        </h6>
                        <h6 className="cap">Price: ${product?.price}</h6>
                        <h6 className="cap">Quantity: {product?.quantity}</h6>
                        <h6 className="cap">Unit: {product?.unit}</h6>
                      </div>
                    ))
                  ) : (
                    <div className="view-inner-cls">
                      <h6>No cart data available</h6>
                    </div>
                  )}
                </>


                <div className="view-inner-cls float-right">
                  <Link className="ant-btn ant-btn-primary" to={`/appointment/`}>Back</Link>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default View;

import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,

} from "antd";

import React, { useEffect, useState } from "react";


import "react-phone-input-2/lib/style.css";
import { Severty, ShowToast } from "../../../helper/toast";
import useRequest from "../../../hooks/useRequest";


const AddDocAdvice = ({ section, api, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);


  const onCreate = (values) => {
    const payload = {
      ...values,
      review :  values.documentation,
      appointment_id :  data?._id,

     };

    request({
      url: `${api.addDocAdvice }`,
      method: "PUT",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };
 
useEffect(()=> {
  if(!data) return;
form.setFieldsValue({
  ...data
})
},[data])

  console.log(data,"data...")


  return (
    <Modal
      open={show}
      width={750}
      okText={"Add"}
      // onOk={onCreate}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
    >
      <Form
        id="create"
        form={form}
        onFinish={onCreate}
        layout="vertical"
        initialValues={{}}
      >
        <div className="add_user_title">
          <h4 className="modal_title_cls">{`Documentation Advice`}</h4>
        </div>
        <Row gutter={[16, 0]}>
        

          <Col span={24} lg={24} sm={24}>
            <Form.Item
              label="Notes for Documentation"
              name="documentation"
              rules={[
                { required: true, message: "Please enter the advice" },
              ]}
            >
              <Input.TextArea  autoComplete="off" placeholder="Enter here.." />
            </Form.Item>
          </Col>

         

         
        </Row>

        
      </Form>
    </Modal>
  );
};

export default AddDocAdvice;

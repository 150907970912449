import {
  Button,
  DatePicker,
  Input,
  Modal,
  Select,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import { Link } from 'react-router-dom';
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import ConfirmationBox from "../../components/ConfirmationBox";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import AddForm from "./AddForm";
import ChangeDoctor from "./ChangeDoctor";
import { CasePaperForm } from "./CaseAndFollowUp";

const { RangePicker } = DatePicker;
const { Option } = Select;

function Index() {
  const heading = lang("Appointment ") + " " + lang("management");
  const { setPageHeading, country } = useContext(AppStateContext);
  const { TabPane } = Tabs;

  const sectionName = "Appointment Manager";
  const routeName = "appointment";
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const appointmentStatus = queryParams.get('appointment_status');

  console.log("get params", queryParams); // This will log the URLSearchParams object
  console.log("appointmnet statu", appointmentStatus); // This will log the value of 'appointment_status';

  const api = {
    appointment: apiPath.appointment,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState("");
  const [caseForm,setCaseForm] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const [startDate, setStartDate] = useState();
  const [addedCaseAndFollowUp, setAddedCaseAndFollowUp] = useState(false);
  const [selectedAppt, setSelectedAppt] = useState();
  const [isAddedCasePaper, setAddedCasepaper] = useState(false);
  const [isAddedFollowUp, setAddedFollowUp] = useState(false);
  const [endDate, setEndDate] = useState();
  const [selectedRecordId, setSelectedRecordId] = useState(null);

   const [dateFilter, setDateFilter] = useState(appointmentStatus?appointmentStatus: "today"); // Default to 'past'
  const navigate = useNavigate()
  const handleChangeStatus = (id, value, type) => {
    const payload = { value: value }
    request({
      url: api.appointment + "/status/" + id + `?type=${type}`,
      method: "put",
      data: payload,
      onSuccess: (data) => {
        if (data.status == false) {
          ShowToast(data.message, Severty.ERROR);
        } else {
          setLoading(false);
          setRefresh((prev) => !prev);
        }

      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  useEffect(() => {
    if (selectedRecordId) {
      // Call your functions and update states based on the result
      addedCasePaper(selectedRecordId);
      addedFollowUps(selectedRecordId);
    }
  }, [selectedRecordId]);
  const handleTabChange = (key) => {
    setDateFilter(key); // Update dateFilter based on the selected tab
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

 
  const columns = [
    {
      title: "S. No",
      dataIndex: "index",
      key: "index",
      render: (value, item, index) =>
        pagination.current === 1
          ? "#" + (index + 1)
          : "#" + ((pagination.current - 1) * 10 + (index + 1)),
    },
    {
      title: "Appointment ID",
      dataIndex: "appointment_id",
      key: "appointment_id",
      render: (_, { appointment_id }) =>
        appointment_id ? <span className="cap">{appointment_id}</span> : "-",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (_, { user, booked_for }) => {
        return (
          <>
            {user ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                }}
              >
                <span className="cap">{user?.name ? user?.name : user?.firstName + " " + user?.lastName}</span>
                {user?.mobile_number && user?.country_code && (
                  <span style={{ color: "gray", fontSize: "12px" }}>
                    {user?.country_code + "-" + user?.mobile_number}
                  </span>
                )}
                {/* {booked_for && (
                  <Tag
                    color={
                      booked_for == "self"
                        ? "green"
                        : booked_for == "relation"
                        ? "blue"
                        : "teal"
                    }
                    key={booked_for}
                    className="cap"
                  >
                    {booked_for}
                  </Tag>
                )} */}
              </div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Patient",
      dataIndex: "user",
      key: "user",
      render: (_, { patient_details, booked_for,_id }) => {
        return (
          <>
            {patient_details ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                }}
              >
                <Link to={`/patient-Detail/${_id}`} className="cap">
                  {patient_details?.name}
                </Link>
                <span className="cap">{patient_details?.email}</span>

                {patient_details?.mobile_number && patient_details?.country_code && (
                  <span style={{ color: "gray", fontSize: "12px" }}>
                    {patient_details?.country_code + "-" + patient_details?.mobile_number}
                  </span>
                )}
                {booked_for && (
                  <Tag
                    color={
                      booked_for == "self"
                        ? "green"
                        : booked_for == "relation"
                          ? "blue"
                          : "teal"
                    }
                    key={booked_for}
                    className="cap"
                  >
                    {booked_for === "relation" ? patient_details?.relationship_with_user : booked_for}
                  </Tag>
                )}
              </div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Doctor",
      dataIndex: "doctor",
      key: "doctor",
      render: (_, { _id, doctor }) => {
        return (
          <>
            {doctor ? (
              <div
                onClick={() => setModal(true)}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                }}
              >
                <span className="cap">{doctor?.name}</span>
                {doctor?.mobile_number && doctor?.country_code && (
                  <span style={{ color: "gray", fontSize: "12px" }}>
                    {doctor?.country_code + "-" + doctor?.mobile_number}
                  </span>
                )}
              </div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },

    {
      title: "Appointment Type",
      key: "appointment_type",
      filters: [
        {
          text: "New",
          value: "New",
        },
        {
          text: "Follow-up",
          value: "Follow-up",
        },
      ],
      render: (_, { appointment_type, _id }) => {
        console.log("Status :: ", appointment_type);
        return (
          <a>
            {appointment_type}
            {/* <Select
              value={appointment_type}
              onChange={(value) => handleChangeStatus(_id, value, "type")}
            >
              <Option value="New">New</Option>
              <Option value="Follow-up">Follow-up</Option>
            </Select> */}
          </a>
        );
      },
    },

    {
      title: "Appointment Category",
      key: "appointment_category",
      filters: [
        {
          text: "Not Assigned",
          value: "NA",
        },
        {
          text: "Reschedule",
          value: "Rescheduled",
        },
        {
          text: "Replacement",
          value: "Replacement",
        },
        {
          text: "Lab Report",
          value: "LabReport",
        },
        {
          text: "Emergency",
          value: "Emergency",
        },
      ],
      render: (_, { appointment_category, _id }) => {
        console.log("Status :: ", appointment_category);
        return (
          <a>
            {appointment_category}
            {/* <Select
              value={appointment_category}
              onChange={(value) => handleChangeStatus(_id, value, "category")}
            >
              <Option value="NA">Not Assigned</Option>
              <Option value="LabReport">Lab Report</Option>
              <Option value="Replacement">Replacement</Option>
              <Option value="Rescheduled">Reschedule</Option>
              <Option value="Emergency">Emergency</Option>
            </Select> */}
          </a>
        );
      },
    },
    {
      title: "Appointment Status",
      key: "appointment_status",
      filters: [
        {
          text: "New",
          value: "pending",
        },
        {
          text: "Completed",
          value: "completed",
        },
        {
          text: "Cancelled",
          value: "cancelled",
        },
      ],
      render: (_, { appointment_status, _id, appointment_date }) => {
        const currentTime = new Date();
        const appointmentTime = new Date(appointment_date);
      console.log(currentTime, "currenttime>>>>>>", appointmentTime, "appointmentTime");
        // Calculate the difference in hours
        const diffInHours = (currentTime - appointmentTime) / (1000 * 60 * 60);
        console.log(diffInHours, "diffInhourss>>>>>>>>>>")
      
        return (
          <a>
            {
              diffInHours <= 24 && diffInHours >= 0 ? (
                // Show Select component if within 24 hours
                <Select
                  value={appointment_status}
                  onChange={(value) => handleChangeStatus(_id, value, "Astatus")}
                >
                  <Option value="completed">Completed</Option>
                  <Option value="cancelled">Cancelled</Option>
                  <Option value="pending">New</Option>
                </Select>
              ) : (
                // Show appointment status otherwise
                appointment_status
              )
            }
          </a>
        );
      }
    },
    {
      title: "Status Reason",
      key: "status",
      filters: [
        {
          text: "Not assigned",
          value: "NA",
        },
        {
          text: "No Show",
          value: "NoShow",
        },
        {
          text: "Patient Cancel",
          value: "patient_cancel",
        },
        {
          text: "Cart",
          value: "Cart",
        },
        {
          text: "Incomplete",
          value: "Incomplete",
        },
        {
          text: "Report Awaited",
          value: "ReportAwaited",
        },
      ],
      render: (_, { status, _id }) => {
        console.log("Status :: ", status);
        return (
          <a>
            <Select
              value={status}
              onChange={(value) => handleChangeStatus(_id, value, "status")}
            >
              <Option value="NA">Not Assigned</Option>
              <Option value="NoShow">No Show</Option>
              <Option value="patient_cancel">Patient Cancel</Option>
              <Option value="Cart">Cart</Option>
              <Option value="Incomplete">Incomplete</Option>
              <Option value="ReportAwaited">Report Awaited</Option>
            </Select>
          </a>
        );
      },
    },
    {
      title: "Scheduled Date",
      key: "date",
      dataIndex: "date",
      render: (_, { appointment_date }) => {
        return moment(appointment_date).format("DD-MMM-YYYY");
      },
    },
    {
      title: "Scheduled Time",
      key: "time",
      dataIndex: "time",
      render: (_, { appointment_date, appointment_time }) => {
        const dateTimeString = `${appointment_date.split('T')[0]}T${appointment_time}`;
        return moment(dateTimeString).format("hh:mm A");
      },
    },


    {
      title: "Booked On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("DD-MMM-YYYY");
      },
    },
    {
      title: "Action",
      render: (_, record) => {
        // if (selectedRecordId !== record._id) {
        //   setSelectedRecordId(record._id);
        // }
        const handleNavigate = () => {
          if (isAddedCasePaper) {
            navigate(`/appointment/add_case/${record._id}`);
          } else if (isAddedFollowUp) {
            navigate(`/appointment/Followup/${record._id}`);
          } else {
            setAddedCaseAndFollowUp(true); // Or any state to handle this case
          }
        };
        return (
          <>
            <Tooltip color={"purple"}
              title={"View " + sectionName}
              key={"viewappointment" + routeName}
            >
              <Button
                className="btnStyle  primary_btn"
                title="View"
                onClick={() => navigate(`/${routeName}/view/${record._id}`)}
              >
                <i className="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip>
            <Tooltip color={"purple"}
              title={"Case Paper "}
              key={"viewappointment" + routeName}
            >
               <Button
                className="btnStyle primary_btn"
                title="Case Paper"
                onClick={handleNavigate}
                onMouseEnter={() => setSelectedRecordId(record._id)}

              >
                <i className="fa fa-light fa-list"></i>
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const addedCasePaper = (id) => {
    request({
      url: apiPath.getCasePaperExist + id,
      method: "GET",
      onSuccess: (data) => {
        if(data.data.data.length>0){
          console.log("length>0")
          setAddedCasepaper(false)
          return;
        }else{
          console.log("length=0")
          setAddedCasepaper(false)

          return
        }
      },
      onError: (error) => {
        console.log("errror:::::::::::",error)
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },

    })
  }

  const addedFollowUps = (id) => {
    request({
      url: apiPath.getAddedFollowUp + id,
      method: "GET",
      onSuccess: (data) => {
        if(data.data.data.length>0){
          setAddedFollowUp(false)
          return;
        }else{
          setAddedFollowUp(true)

          return
        }
      },
      onError: (error) => {
        console.log("errror:::::::::::",error)
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },

    })
  }

  useEffect(() => {
    setLoading(true);
    fetchData(pagination);
  }, [refresh, debouncedSearchText, startDate, endDate,dateFilter]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.status : null;
    const selectedSegment = filters ? filters.appointment_type : null
    const selectedTab = filters ? filters.appointment_status : null
    const selectedCategory = filters ? filters.appointment_category : null

    request({
      url:
        api.appointment +
        `?page=${pagination ? pagination.current : 1
        }&pageSize=${pagination?.pageSize ? pagination.pageSize : 10
        }&search=${debouncedSearchText}&appointment_type=${selectedSegment ? selectedSegment : ''}&appointment_status=${selectedTab ? selectedTab : appointmentStatus}&appointment_category=${selectedCategory ? selectedCategory : ''}&status=${filterActive ? filterActive : ''}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""} &date_filter=${dateFilter}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data.docs);
        setPagination((prev) => ({
          current: pagination.current,
          total: data.data.totalDocs,
        }));
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);

        ShowToast(error, Severty.ERROR);
      },
    });
  };


  const onSearch = (e) => {
    setSearchText(e.target.value);
    setPagination({ current: 1 });
  };


  const items = [
    {
      key: 'new',
      label: 'Upcoming',
    },
    {
      key: 'completed',
      label: 'Completed',
    },
    {
      key: 'cancelled',
      label: 'Cancelled',
    },
  ];

  const calculateAge = (dob) => {
    console.log("dob????????????????", dob)
    if (!dob) return null;

    const today = moment();
    const birthDate = moment(dob, 'DD-MM-YYYY'); // Specify the format 'DD-MM-YYYY'
    const years = today.diff(birthDate, 'years');
    birthDate.add(years, 'years');
    const months = today.diff(birthDate, 'months');
    birthDate.add(months, 'months');
    const days = today.diff(birthDate, 'days');
    console.log(years, months, days)
    return { years, months, days };
  };

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
    } else {
      setStartDate()
      setEndDate()
    }
  };

  return (
    <>
      <SectionWrapper
        cardHeading={`Appointments`}
        extra={
          <>
            <div className="w-100 text-head_right_cont">
              <div className="pageHeadingSearch d-flex gap-2">
                <RangePicker style={{ height: 42 }} disabledDate={(current) => current.isAfter(Date.now())} onChange={handleChangeDate} />
                <Input.Search
                  className="searchInput"
                  placeholder="Search by customer name, Phone number, email"
                  onChange={onSearch}
                  allowClear
                />
              </div>
              {/* <Button className="btnStyle  primary_btn" onClick={() => handleExport()}>Export</Button> */}
            </div>
          </>
        }
      >
     <div className="p-3">
      <Tabs activeKey={dateFilter} onChange={handleTabChange} className="myclients-tab">
       
        <TabPane tab={<span className="myclients-tab-button">Today</span>} key="today" className="myclients-tab-body">
          <div className="sssss" style={{ overflowX: "scroll" }}>
            <Table
              loading={loading}
              columns={columns}
              dataSource={list}
              pagination={pagination}
              onChange={handleChange}
              className="ant-border-space"
            />
          </div>
        </TabPane>
        <TabPane tab={<span className="myclients-tab-button">Future</span>} key="future" className="myclients-tab-body">
          <div className="sssss" style={{ overflowX: "scroll" }}>
            <Table
              loading={loading}
              columns={columns}
              dataSource={list}
              pagination={pagination}
              onChange={handleChange}
              className="ant-border-space"
            />
          </div>
        </TabPane>
        <TabPane tab={<span className="myclients-tab-button">Completed</span>} key="past" className="myclients-tab-body">
          <div className="sssss" style={{ overflowX: "scroll" }}>
            <Table
              loading={loading}
              columns={columns}
              dataSource={list}
              pagination={pagination}
              onChange={handleChange}
              className="ant-border-space"
            />
          </div>
        </TabPane>
      </Tabs>
    </div>


      </SectionWrapper>

      {visible && (
        <AddForm
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {
        addedCaseAndFollowUp && (
          <Modal
          open={addedCaseAndFollowUp}
      width={750}
      onCancel={() => setAddedCaseAndFollowUp(false)}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
          >
            <h4>Follow-Up already added for this appointment.</h4>
            <Button onClick={() => setAddedCaseAndFollowUp(false)}>Back</Button>
          </Modal>
        )
      }

      {modal && (
        <ChangeDoctor
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

     
    </>
  );
}

export default Index;

import {
  Button,
  Table,
  Tooltip,
  Tag,
  Avatar,
  Image, Row, Col, Select,
  Input,
  DatePicker,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import useApi from "../../hooks/useApi";

import Plus from "../../assets/images/plus.svg";
import ConfirmationBox from "../../components/ConfirmationBox";
import DeleteModal from "../../components/DeleteModal";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { AppStateContext, useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import AddFrom from "./_AddFrom";
import ViewAvailability from "./ViewAvailability";

import { useNavigate } from "react-router";


const { RangePicker } = DatePicker;
const { Option } = Select;


function Index() {
  const heading = lang("Cart") + " " + lang("management");
  const { setPageHeading } = useContext(AppStateContext);
  const { country } = useAppContext();
  const { getState, getCity, getCountry } = useApi();

  const sectionName = "Cart";
  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("status");

  const api = {
    doctor: apiPath.getCart,
    updateCart : apiPath.updatecart
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [docs,setDocs] = useState([])
  const [countries, setCountries] = useState()
  const [states, setStates] = useState()

  const [cities, setCities] = useState()
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [startDate, setStartDate] = useState();
  const [availability, setAvailability] = useState(false);

  const [endDate, setEndDate] = useState();
  const [selectedOptionsCountries, setSelectedOptionsCountries] = useState();
  const [selectedState, setselectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();

  const view = (id) => {
    navigate(`/doctor/view/${id}`);
  };

  const activity = (id) => {
    navigate(`/user/activity/${id}`);
  };

  const onDelete = (id) => {
    request({
      url: api.doctor + "/" + id,
      method: "DELETE",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChangeStatus = (id, status) => {
    console.log("check Id", id, status)
    request({
      url: `${api.updateCart}/${id}/${status}`,
      method: "PUT",
      onSuccess: (data) => {
        console.log(data);
        if (data.status === false) {
          ShowToast(data.message, Severty.ERROR)
        }
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const columns = [
    {
      title: "Cart ID",
      dataIndex: "cartId",
      key: "cartId",
    },
    {
      title: "Doctor Name",
      dataIndex: "doctorDetails",
      key: "doctorName",
      render: (doctorDetails) => doctorDetails ? doctorDetails[0]?.name : "-"
    },
    {
      title: "Patient Name",
      dataIndex: "patientDetails",
      key: "patientName",
      render: (patientDetails) => patientDetails ? patientDetails[0]?.name : "-"
    },
    {
      title: "User Name",
      dataIndex: "userDetails",
      key: "userDetails",
      render: (userDetails) => userDetails ? userDetails[0]?.name : "-"
    },

    {
      title: "Patient Phone Number",
      key: "patientPhone",
      render: (_, { patientDetails }) => (
        <span>
          {patientDetails[0]?.country_code ? `${patientDetails[0]?.country_code}-` : "+91"}
          {patientDetails[0]?.mobile_number}
        </span>
      ),
    },
    {
      title: "Products",
      dataIndex: "productDetails",
      key: "products",
      render: (productDetails) => (
        <>
          {productDetails?.map((product, index) => (
            <div key={index} style={{ marginBottom: "8px" }}>
              {/* <Avatar
                style={{ backgroundColor: "#00a2ae", verticalAlign: "middle" }}
                className="cap"
                size={40}
                src={product.image}
              /> */}
              <span style={{ marginLeft: "12px" }}>{product.name}</span>
            </div>
          ))}
        </>
      ),
    },
    {
      title: "Product Price",
      dataIndex: "productDetails",
      key: "productPrice",
      render: (productDetails) => (
        <>
          {productDetails?.map((product, index) => (
            <div key={index} style={{ marginBottom: "8px" }}>
              <span>${product.price}</span>
            </div>
          ))}
        </>
      ),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (duration) => (
        <span>{duration}</span>
      ),
    },
    {
      title: "Cart Status",
      dataIndex: "cartStatus",
      key: "cartStatus",
      render: (cartStatus, record) => (
        <>
          <Tag
            color={cartStatus === 'checkout' ? 'green' : cartStatus === 'expiry' ? 'red' : 'orange'}
            onClick={() => {
              setShowStatus(true); // Open the modal
              setSelected(record); // Set the selected cart item
            }}
            style={{ cursor: "pointer" }}
          >
            {cartStatus}
          </Tag>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div div className="d-flex justify-contenbt-start">
            <Tooltip title={"Edit"} color={"purple"} key={"edit"}>
              <Button
                className="edit-cls btnStyle primary_btn"
                onClick={() => {
                  setSelected(record);
                  setVisible(true);
                }}
              >
                {/* <img src={EditIcon} /> */}
                <i className="fas fa-edit"></i>
                {/* <span>Edit</span> */}
              </Button>
            </Tooltip>

          </div>
        );
      },
    },    
  ];

  const handleChangeCountries = (selectedValues) => {
    setSelectedOptionsCountries(selectedValues);
    fetchData()
    getState({
      countryId: selectedValues,
      stateData: (data) => {
        setStates(data)
      }
    })
  };

  useEffect(() => {
    getCountry({
      countryData: (data) => setCountries(data)
    });
  }, [])
  useEffect(() => {
    setLoading(true);
    fetchData({ ...pagination, current: 1 });
  }, [refresh, debouncedSearchText, startDate, endDate, selectedCity, selectedState, selectedOptionsCountries]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const fetchData = (pagination, filters) => {
    console.log(filters, "filters>>>>>>>>>>>>>>>>>..........");
    const filterActive = filters ? filters.is_active : null;
    const filterName = filters ? filters.name : null;
    const filterEmail = filters ? filters.email : null;
    const filterIsHeadDoctor = filters ? filters.is_head_doctor : null; // Add is_head_doctor filter


    request({
      url:
        api.doctor +`?page=${pagination ? pagination.current :1 }&pageSize=${pagination?.pageSize ? pagination.pageSize : 10}` ,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          console.log(data.docs)
          setList(data.docs);
          setDocs(data)

          setPagination((prev) => ({
            current: pagination.current,
            total: data.totalDocs,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };


  return (
    <>
      <SectionWrapper
        cardHeading={lang("Cart") + " " + lang("list")}
        extra={
          <>
            <div className="w-100 d-grid align-items-baseline text-head_right_cont">
              <div className="pageHeadingSearch pageHeadingbig d-flex gap-2">
                <Button
                  className="primary_btn btnStyle"
                  onClick={(e) => {
                    setVisible(true);
                    setSearchText("");
                  }}
                >
                  <span className="add-Ic">
                    <img src={Plus} />
                  </span>
                  Add {sectionName}
                </Button>
                {/* <Button className="btnStyle  primary_btn" onClick={() => handleExport()}>Export</Button> */}
              </div>
            </div>
          </>
        }
      >
        <div className="table-responsive customPagination">
          <h4 className="text-right">Total Records: {docs?.totalDocs}</h4>
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={pagination}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>

      {visible && (
        <AddFrom
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {availability && (
        <ViewAvailability
          section={sectionName}
          api={api}
          show={availability}
          hide={() => {
            setSelected();
            setAvailability(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )
      }
      {showDelete && (
        <DeleteModal
          title={"Delete User"}
          subtitle={`Are you sure you want to Delete this user?`}
          show={showDelete}
          hide={() => {
            setShowDelete(false);
            setSelected();
          }}
          onOk={() => onDelete(selected?._id)}
        />
      )}

      {showStatus && selected?._id && (
        <DeleteModal
          title={`Change Cart Status`}
          subtitle={`Are you sure you want to change the status of this cart?`}
          show={showStatus}
          hide={() => {
            setShowStatus(false);
            setSelected();
          }}
          onOk={() => handleChangeStatus(selected?._id, selected?.cartStatus === 'checkout' ? 'expiry' : 'checkout')}
        />
      )}

    </>
  );
}

export default Index;



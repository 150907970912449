import React, { useState,useEffect } from "react";
import { Modal, Form, Input, Select, InputNumber, Button, Card, Tag, Row, Col } from "antd";
import useRequest from "../../hooks/useRequest";
import { Link, useParams } from "react-router-dom";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import CasepaperImg from "../../assets/images/casepaperm-Img.png";
import { useNavigate } from "react-router";
import { values } from "lodash";

const { TextArea } = Input;

const { Option } = Select;

const tags = ['Diabetes', 'Diabetes', 'Diabetes', 'Diabetes', 'Diabetes', 'Diabetes'];

const AddCase = ({ show, hide, refresh }) => {
  const [formKey, setFormKey] = useState(0);
  const [form] = Form.useForm();
  const params = useParams();
  const { request } = useRequest();
  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const [detail,setpatientDetail] = useState({})
  const [loading, setLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(true); // State to manage form editability

 
    const fetchPatientData = async (id) => {
      request({
        url: `${apiPath.AppointDetail}/${id}`,
        method: 'GET',
        onSuccess: (data) => {
          setpatientDetail(data.data);
          setLoading(false);
        },
        onError: (error) => {
          console.error('Error fetching patient data:', error);
          setLoading(false);
        },
      });
    };

  useEffect(() => {
    setLoading(true);
    console.log(params.id, "aprams id>>>>>>>>>>");
    fetchPatientData(params.id);
  }, []);


  console.log("detail::::::::::::::::", detail.patient_id)

  const onCreate = async (values) => {
    console.log("values::::::::", values)
    const payload = {
      patient_id: detail?.patient_id._id || null,
      appointment_id: params.id || null,
      referredBy: values.referredBy || '',
      present_complaints: values.present_complaints || '',
      past_history: values.past_history || '',
      current_past_medication: values.current_past_medication || '',
      family_history: [{
        father_name: values.father || '',
        mother_name: values.mother || '',
        sister_name: values.sister || '',
        brother_name: values.brother || '',
      }],
      dincharya: [{
        wakeup_at_AM: values.wakeup_at_AM || '',
        sleeps_at_PM: values.sleeps_at_PM || '',
        exercise: values.exercise || '',
        job_profile: values.job_profile || '',
        hunger: values.hunger || '',
      }],
      diet: [{
        tea: [{
          time: values.tea?.[0]?.time || '',
          enter: values.tea?.[0]?.enter || ''
        }],
        breakfast: [{
          time: values.breakfast?.[0]?.time || '',
          enter: values.breakfast?.[0]?.enter || ''
        }],
        lunch: [{
          time: values.lunch?.[0]?.time || '',
          enter: values.lunch?.[0]?.enter || ''
        }],
        snacks: [{
          time: values.snacks?.[0]?.time || '',
          enter: values.snacks?.[0]?.enter || ''
        }],
        dinner: [{
          time: values.dinner?.[0]?.time || '',
          enter: values.dinner?.[0]?.enter || ''
        }],
        any_other_food: [{
          time: values.any_other_food?.[0]?.time || '',
          enter: values.any_other_food?.[0]?.enter || ''
        }],
        sweets: values.sweets || '',
        hot_and_spicy: values.hot_and_spicy || '',
        food_type: values.food_type || '',
        stale_food: values.stale_food || '',
        hoteling: values.hoteling || ''
      }],
      other_details: [{
        bowel_habit: values.bowel_habit || '',
        urination: values.urination || '',
        nocturnal_urination: values.nocturnal_urination || '',
        menstrual_history: values.menstrual_history || '',
        sleep: values.sleep || '',
        day_time_sleep: values.day_time_sleep || '',
        mental_stress: values.mental_stress || '',
        addictions: values.addictions || ''
      }],
      physical_examination: [{
        jivha: values.jivha || '',
        nadi: values.nadi || '',
        aakruti: values.aakruti || '',
        shabda: values.shabda || '',
        pulse: values.pulse || '',
        sparsha: values.sparsha || '',
        bp: values.bp || '',
        weight: values.weight || ''
      }],
      investigations: values.investigations || '',
      diagnosis: values.diagnosis || [],
      treatment: values.treatment || []
    };
  
    console.log('Payload:', payload);  // Debugging line to check the payload)
      request({
        url: apiPath.addCasePaper,
        method: "POST",
        data: payload,
        onSuccess: (data) => {
          ShowToast("Case paper added successfully", Severty.SUCCESS); // Adjusted success message
          setIsEditable(false); 
          form.resetFields();
          setFormKey(prevKey => prevKey + 1); // Force a re-render by updating the form ke
           navigate(`/patient-Detail/${params.id}`);
          setLoading(false);
        },
        onError: (error) => {
          console.log("errror:::::::::::",error)
          ShowToast(error.response.data.message, Severty.ERROR);
          setLoading(false);
        },
      });
    };

 
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputPressEnter = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]);
      setInputValue('');
    }
  };

  const handleClose = (removedTag) => {
    const newTags = tags.filter(tag => tag !== removedTag);
    setTags(newTags);
  };

  return (
    <>
    <Form key={formKey} id="create" form={form} layout="vertical" onFinish={onCreate}>
      <div className="mian-all-case-details">
        <div className="ant-card">
          <div className="ant-card-head">
            <div className="ant-card-head-title">Basic Details</div>
          </div>
          <div className="ant-card-body">
            <div className="main-case-profile">
              <img src={CasepaperImg} alt="Casepaper" />
              <div className="case-p-details-m">
                <h6>{detail?.patient_id?.uhid}</h6>
                <h5>{detail?.patient_id?.name} ({detail?.patient_id?.age})</h5>
                {/* <h4>India</h4> */}
              </div>
              <p>{detail?.patient_id?.gender}</p>
            </div>
          </div>
        </div>
            <div className="reffer-by-img">
              <h2>Referred By</h2>
              <Form.Item name="referredBy" rules={[{ required: true, message: 'Please enter referred by' }]}>
                <Input type="text" placeholder="Enter name" disabled={!isEditable} />
              </Form.Item>
            </div>

        <div className="ant-card">
          <div className="ant-card-head">
            <div className="ant-card-head-title">Present Complaints</div>
          </div>
          <div className="ant-card-body">
            <Form.Item name="present_complaints" rules={[{ required: true, message: 'Please enter present complaints' }]}>
              <TextArea placeholder="Enter..."disabled={!isEditable} style={{ width: "100%", height: "200px" }} />
            </Form.Item>
          </div>
        </div>

        <Card title="Past History">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Input
                placeholder="Search..."
                value={inputValue}
                onChange={handleInputChange}
                onPressEnter={handleInputPressEnter}
              />
            </Col>
            <Col span={24} style={{ marginTop: '16px' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                {tags.map((tag, index) => (
                  <Tag
                    key={index}
                    className="main-input-img"
                  >
                    {tag}
                    <button
                      onClick={() => handleClose(tag)}
                    >X</button>
                  </Tag>
                ))}
              </div>
            </Col>
            <Col span={24} style={{ marginTop: '16px' }}>
              <Form.Item name="past_history" rules={[{ required: true, message: 'Please enter past history' }]}>
                <TextArea placeholder="Enter..."disabled={!isEditable} style={{ width: '100%', height: '200px' }} />
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <Card title="Current and Past Medication" style={{ marginTop: '20px' }}>
          <Form.Item name="current_past_medication" rules={[{ required: true, message: 'Please enter current and past medication' }]}>
            <TextArea placeholder="Enter..." disabled={!isEditable}style={{ width: '100%', height: '200px' }} />
          </Form.Item>
        </Card>

        <Card title="Family History">
          <Row gutter={[16, 16]}>
            <Col span={24} md={12}>
            <h5>Father Name</h5>
              <Form.Item name="father" >
               
                <Input placeholder="Enter..." disabled={!isEditable}style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
            <label>Mother</label>
              <Form.Item name="mother_name" >
                <Input placeholder="Enter..." disabled={!isEditable}style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
            <label>Sister</label>
              <Form.Item name="sister_name" >               
                <Input placeholder="Enter..." disabled={!isEditable}style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Brother</label>
              <Form.Item name="brother_name" >
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <Card title="Dinacharya" style={{ marginTop: '20px' }}>
          <Row gutter={[16, 16]}>
            <Col span={24} md={12}>
                <label>Wake up at AM</label>
              <Form.Item name="wakeup_at_AM" >
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Sleeps at PM</label>
              <Form.Item name="sleeps_at_PM" >
                <Input placeholder="Enter..."disabled={!isEditable}  style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Exercise</label>
              <Form.Item name="exercise" >
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Job profile</label>
              <Form.Item name="job_profile" >
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Hunger</label>
              <Form.Item name="hunger">
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <Card title="Diet" style={{ marginTop: '20px' }}>
          <Row gutter={[16, 16]}>
            <Col span={24} md={12}>
                <label>Tea</label>
              <Form.Item name="tea" >
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Breakfast</label>
              <Form.Item name="breakfast">
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Lunch</label>
              <Form.Item name="lunch">
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Snacks</label>
              <Form.Item name="snacks">
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Dinner</label>
              <Form.Item name="dinner" >
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Any other food</label>
              <Form.Item name="any_other_food">
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Sweets</label>
              <Form.Item name="sweets">
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Hot and spicy</label>
              <Form.Item name="hot_and_spicy" >
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Food type</label>
              <Form.Item name="food_type" >
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Stale food</label>
              <Form.Item name="stale_food" >
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Hoteling</label>
              <Form.Item name="hoteling">
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <Card title="Other Details" style={{ marginTop: '20px' }}>
          <Row gutter={[16, 16]}>
            <Col span={24} md={12}>
                <label>Bowel habit</label>
              <Form.Item name="bowel_habit" >
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Urination</label>
              <Form.Item name="urination" >
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Nocturnal urination</label>
              <Form.Item name="nocturnal_urination" >
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Menstrual history</label>
              <Form.Item name="menstrual_history">
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Sleep</label>
              <Form.Item name="sleep" >
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Day time sleep</label>
              <Form.Item name="day_time_sleep" >
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Mental stress</label>
              <Form.Item name="mental_stress" >
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Addictions</label>
              <Form.Item name="addictions">
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card title="Physical Examination" disabled={!isEditable} style={{ marginTop: '20px' }}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="jivha">
                  <Input placeholder="Jivha" disabled={!isEditable}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="nadi">
                  <Input placeholder="Nadi" disabled={!isEditable} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="aakruti">
                  <Input placeholder="Aakruti" disabled={!isEditable}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="shabda">
                  <Input placeholder="Shabda" disabled={!isEditable}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="pulse">
                  <Input placeholder="Pulse" disablded={!isEditable} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="sparsha">
                  <Input placeholder="Sparsha" disabled={!isEditable} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="bp">
                  <Input placeholder="Blood Pressure (BP)" disabled={!isEditable}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="weight">
                  <Input placeholder="Weight" disabled={!isEditable} />
                </Form.Item>
              </Col>
            </Row>
          </Card>

        <Card title="Previous History" style={{ marginTop: '20px' }}>
          <Row gutter={[16, 16]}>
            <Col span={24} md={12}>
                <label>Allergies</label>
              <Form.Item name="allergies" >
                <Input placeholder="Enter..." disabled={!isEditable}style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Medications</label>
              <Form.Item name="medications" >
                <Input placeholder="Enter..." disabled={!isEditable}style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Surgeries</label>
              <Form.Item name="surgeries" >
                <Input placeholder="Enter..." disabled={!isEditable}style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Chronic diseases</label>
              <Form.Item name="chronic_diseases" >
                <Input placeholder="Enter..." disabled={!isEditable}style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Infectious diseases</label>
              <Form.Item name="infectious_diseases" >
                <Input placeholder="Enter..." disabled={!isEditable}style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
                <label>Hospitalizations</label>
              <Form.Item name="hospitalizations" >
                <Input placeholder="Enter..." disabled={!isEditable} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>


      </div>
    </Form>
    </>
  )
};

export default AddCase;

import { Button, Image, Table, Tooltip, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";
import notfound from "../../assets/images/not_found.png";
import Plus from "../../assets/images/plus.svg";
import SectionWrapper from "../../components/SectionWrapper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import AdvertisementBannerForm from "./AdvertisementBannerForm";
import apiPath from "../../constants/apiPath";
import DeleteModal from "../../components/DeleteModal";
import EditIcon from "../../assets/images/edit.svg";

const { confirm } = Modal;

const BannerManager = ({ sectionName }) => {
  const [pagination, setPagination] = useState({
    current: 1,
    total: 10,
  });

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showDelete, setShowDelete] = useState(false);

  const [selectedAddBanner, setSelectedAddBanner] = useState();
  const [visible, setVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { request } = useRequest();

  const handleChange = (pagination, sorter, filters) => {
    if (!sorter) {
      setPagination((prev) => ({
        current: pagination.current,
        total: 10,
      }));
    }
  };

  const onDelete = (record) => {
    request({
      url: apiPath.banner + "/" + record?._id,
      method: "DELETE",
      onSuccess: (data) => {
        setLoading(false);
        if(data.status){
          ShowToast(data.message,Severty.SUCCESS)
        }else{
          ShowToast(data.message,Severty.ERROR)
        }
    
        fetchData(pagination);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const fetchData = () => {
    request({
      url:
        apiPath.banner +
        `?status=${""}&page=${pagination ? pagination.current : 1}&pageSize=${
          pagination && pagination?.total ? pagination?.total : 10
        }&search=${""}`,
      method: "GET",
      onSuccess: (data) => {
        setList(data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const columns = [
    {
      title: "Banner",
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => (
        <Image
          width={40}
          src={image ? image : notfound}
          className="table-img"
        />
      ),
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (_, { start_date }) => {
        return moment(start_date).format("DD-MMM-YYYY");
      },
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (_, { end_date }) => {
        return moment(end_date).format("DD-MMM-YYYY");
      },
    },
    {
      title: "Advertisement Company Name",
      dataIndex: "company",
      key: "name",
      render: (_, { name ,restaurant_id }) => {
        return name ? <span className="cap">{name}</span> : (restaurant_id ?  <span className="cap">{restaurant_id.name}</span> :"-");
      },
    },
    {
      title: "Contact Details",
      dataIndex: "contact_details",
      key: "contact_details",
      render: (_, { country_code, mobile_number ,restaurant_id }) => {
        return country_code && mobile_number
          ? "+" + country_code + " " + mobile_number
          : (restaurant_id ? "+" + restaurant_id.country_code + " " + restaurant_id.mobile_number :"-");
      },
    },
    // {
    //   title: "Company Category",
    //   dataIndex: "category_id",
    //   key: "category_id",
    //   render: (_, { category_id }) => {
    //     return category_id ? <span className="cap">{category_id}</span> : "-";
    //   },
    // },
    {
      title: "Banner Position",
      dataIndex: "banner_position",
      key: "banner_position",
      render: (_, { position }) => {
        return position ? <span className="cap">{position}</span> : "-";
      },
    },
    {
      title: "URL",
      dataIndex: "link",
      key: "link",
      render: (_, { link }) => {
        return link ? (
          <Link style={{}} to={link} target="_blank">
            {link}
          </Link>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div div className="d-flex justify-contenbt-start">
            <Tooltip
              title={"Edit"}
              color={"purple"}
              key={"update"}
            >
              <Button
                title="Edit"
                className="edit-cls btnStyle primary_btn"
                onClick={() => {
                  setSelectedAddBanner(record);
                  setVisible(true);
                }}
              >
                <img src={EditIcon} />
                <span>Edit</span>
              </Button>
            </Tooltip>

            <Tooltip title={"Delete"} color={"purple"} key={"Delete"}>
              <Button
                title="Delete"
                className="btnStyle deleteDangerbtn"
                onClick={(e) => {
                  setSelectedAddBanner(record);
                  setShowDelete(true);
                }}
              >
                <img src={deleteWhiteIcon} />
                <span>Delete</span>
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchData(pagination);
  }, [refresh]);

  return (
    <>
      <SectionWrapper
        cardHeading={"Advertisement Manager"}
        extra={
          <>
            <div className="w-100 text-head_right_cont">
              <Button
                className="primary_btn btnStyle"
                onClick={(e) => {
                  setVisible(true);
                }}
              >
                <span className="add-Ic">
                  <img src={Plus} />
                </span>
                Add Banner
              </Button>
            </div>
          </>
        }
      >
        <div className="table-responsive customPagination">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>

      {visible && (
        <AdvertisementBannerForm
          type={sectionName}
          sectionName={sectionName}
          show={visible}
          hide={() => {
            setSelectedAddBanner();
            setVisible(false);
          }}
          ant-btn-default
          data={selectedAddBanner}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {showDelete && (
        <DeleteModal
          title={"Delete Banner"}
          subtitle={`Are you sure you want to Delete this Banner?`}
          show={showDelete}
          hide={() => {
            setShowDelete(false);
            setSelectedAddBanner();
          }}
          onOk={() => onDelete(selectedAddBanner)}
        />
      )}
    </>
  );
};

export default BannerManager;

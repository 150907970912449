import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Tabs,
  DatePicker,
  Skeleton,
  Typography,
  Checkbox,
  TimePicker,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  RightOutlined,
  LeftOutlined
} from "@ant-design/icons";
import moment from "moment";
import React, { useContext, useEffect, useState  } from "react";
import { Navigate, useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";  
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import { AppStateContext } from "../../context/AppContext";
import OwlCarousel from "react-owl-carousel3";
import { renderToStaticMarkup } from "react-dom/server";
const Search = Input.Search;

const onChange = (e) => {
  console.log(`checked = ${e.target.checked}`);
};
const { TabPane } = Tabs;

const { Title, Text } = Typography;

export const QuoteStatus = {
  REQUEST: "request",
  RECEIVED: "received",
  COMPLETE: "complete",
  FULLFILL: "fulfill",
  ADDONS: "addons",
  ITEMDEALS: "itemdeals",
};

function Index() {
  const [loading, setLoading] = useState(false);
  const heading = ("Availability management");
  const { setPageHeading } = useContext(AppStateContext);
  const [date, setDate] = useState(new Date()); // current date
  const [datesList, setDatesList] = useState([]);
  const [list,setList] = useState([])
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [filteredAvailability, setFilteredAvailability] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const api = {
    status: apiPath.statusQuote,
    list: apiPath.listQuote,
  };
  const navigate = useNavigate();
  const { request } = useRequest();

  const fetchData = () => {
    request({
      url:apiPath.getAvailibility,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data.data);       
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);

        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    fetchData()
  }, []);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);
  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];
console.log("list::::::::::::::::",list)

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getDatesInMonth = (year, month) => {
    const dates = [];
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    for (let day = 1; day <= lastDay.getDate(); day++) {
      const date = new Date(year, month, day);
      if (date >= today) {
        const dayOfWeek = weekDays[date.getDay()];
        dates.push({
          date: date,
          dayOfWeek: dayOfWeek,
        });
      }
    }

    return dates;
  };

  useEffect(() => {
    setDatesList(getDatesInMonth(date.getFullYear(), date.getMonth()));
  }, [date]);

 


  const handlePrevMonth = () => {
    const newDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    setDate(newDate);
  };

  const handleNextMonth = () => {
    const newDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    setDate(newDate);
  };





  useEffect(() => {
    if (selectedDate) {
      const dayName = weekDays[selectedDate.getDay()];
      const filteredData = list.filter(  (item) => item.availability_day === dayName && item.is_active && !item.is_deleted );
      setFilteredAvailability(filteredData);
    }
  }, [selectedDate, list]);






  const styles = {
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "baseline",
    },
    text: {
      fontSize: "20px",
      fontWeight: "bold",
    },
    arrows: {
      display: "flex",
      alignItems: "baseline",
    },
    arrow: {
      fontSize: "26px",
      margin: "0 10px",
      cursor: "pointer",
      fontWeight: "600",
    },
    month: {
      fontSize: "16px",
      fontWeight: "bold",
      width: "80px",
      textAlign: "center",
    },
    year: {
      fontSize: "16px",
      fontWeight: "bold",
    },
  };


  const handleButtonClick = () => {
    navigate('/EditAvailability', '_blank');
  };


  return (
    <>
      <div className="home-card tabled quoteManagement card_body_space">
        <Row gutter={[24, 0]}>
          <Col span={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div className="graph-title">
                <Title level={5}>Availability</Title>
              </div>
             
              <div>
      <div className="graph_inner_title date-g">
        <h3>Select date</h3>
        <div style={styles.header}>
          <div style={styles.arrows}>
            <span style={styles.arrow} onClick={handlePrevMonth}>
              &lt;
            </span>
            <span style={styles.month}>{months[date.getMonth()]}</span>
            <span style={styles.arrow} onClick={handleNextMonth}>
              &gt;
            </span>
          </div>
        </div>
      </div>
      
      <div className="date-list">
                  <ul>
                  {datesList.map((item, index) => (
                      <li
                        key={index}
                        className={selectedDate && selectedDate.getTime() === item.date.getTime() ? 'active' : ''}
                        onClick={() => setSelectedDate(item.date)}
                      >
                        {console.log("dateList", datesList, "index", index)}
                        <div className="date-circsl">
                          <span>{item.dayOfWeek}</span>
                           <span>{item.date.getDate()}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
             </div>
         
              </div>

              <div className="time-schedule-container">
                <Row gutter={24} className="mt-3">
                  {filteredAvailability.length ? (
                    filteredAvailability.map((availability) => (
                      <Col span={24} md={8} key={availability.availability_id}>
                        <div className="time-card">
                          <div className="time-card-content">
                            <div className="time">
                              <h5>Start Time</h5>
                              <h6>{moment(availability.availability_time_from).format("hh:mm a")}</h6>
                            </div>
                            <div className="arrow">
                              <RightOutlined />
                            </div>
                            <div className="time">
                              <h5>End Time</h5>
                             
                              <h6>{moment(availability.availability_time_to).format("hh:mm a")}</h6>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <Col span={24}>
                      <p>No availability for this day.</p>
                    </Col>
                  )}
                </Row>
                <div className="edit-icon-dlttt-main">
                  <button onClick={handleButtonClick}>
                    <EditOutlined />
                  </button>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Index;